import { AxiosResponse } from 'axios';
import { RECOMMENDATION_API, TEAMS_API } from './axios';
import { config } from 'config/config.local';
import { IGenIAResponse, ISearchGenIAParams } from 'contexts/gen_ia/types';

export const searchText = async ({
  prompts,
  fileTypes,
}: ISearchGenIAParams): Promise<AxiosResponse<IGenIAResponse>> => {
  const paramsParser = {
    prompts: prompts,
    indexes: fileTypes,
  };
  return await RECOMMENDATION_API.post(config.routes.genIA.search, paramsParser);
};

export const downloadOperation = async (id: string) => {
  return await TEAMS_API.get(`${config.routes.genIA.operations}${id}/download/`);
};

export const getOperations = async (page: number, page_size: number) => {
  return await TEAMS_API.get(config.routes.genIA.operations, {
    params: {
      page,
      page_size,
    },
  });
};
