import styled from 'styled-components';
import CounterCard from 'components/cards/CounterCard';
import { useContext, useEffect, useState } from 'react';
import { CertificationContext } from 'contexts/certifications';
import { SessionContext } from 'contexts/session';
import { screenSm } from 'constants/styles/breakpoints';

const Container = styled.div<{ mobileOnly?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 10px;
  background-color: #f2f2f7;
  padding: ${({ mobileOnly }) => (mobileOnly ? '0' : '10px')};
  padding: 15px 0;
  ${screenSm} {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  div {
    :nth-child(1) {
      grid-column: span 1;
    }
    :nth-child(2) {
      order: initial;
    }
    height: 100%;
    ${screenSm} {
      height: auto;
    }
  }
`;

const CertificationsCounters = (): JSX.Element => {
  const { fetching, counters } = useContext(CertificationContext);
  const { isAccountOwner } = useContext(SessionContext);

  const [dataDisplay, setDataDisplay] = useState([]);
  const mobileOnly = false;

  const roleLabel = isAccountOwner ? 'account' : 'team(s)';
  useEffect(() => {
    setDataDisplay(() => {
      return [
        {
          key: 'total_vouchers_assigned',
          tooltipLabel: null,
          counterTooltipLabel: `Total number of vouchers assigned to users in your ${roleLabel} that can still be used`,
          disabled: true,
          counterLabel: 'Total vouchers assigned',
        },
        {
          key: 'percentage_of_users_with_vouchers',
          counterTooltipLabel: `Percentage of users in your ${roleLabel} who have active vouchers assigned`,
          counterLabel: 'Users with vouchers assigned',
          percentageKey: 'percentage_of_users_with_vouchers',
          disabled: true,
        },
        {
          key: 'total_certification_attempts_taken',
          tooltipLabel: null,
          counterTooltipLabel: `Total number of attempts in your ${roleLabel}, including both passed and failed`,
          disabled: true,
          counterLabel: 'Total certifications attempts taken',
        },
        {
          key: 'total_passed_certification_attempts',
          tooltipLabel: null,
          counterTooltipLabel: `Total number of approved certifications in your ${roleLabel} that are still valid`,
          disabled: true,
          counterLabel: 'Passed Certifications',
        },
      ];
    });
  }, []);

  const getQuantity = (key, percentageKey?) => {
    const value = counters[key];
    if (percentageKey) return `${value}%`;
    return getValue(key, value);
  };

  const getLabel = (key, showQtyInLabel, label?, mobileCounterLabel?) => {
    if (showQtyInLabel) {
      return `${getQuantity(key)} ${label || key}`;
    }
    const currentLabel = label || key;
    return mobileOnly ? mobileCounterLabel || currentLabel : currentLabel;
  };

  const getValue = (key: string, value: number) => {
    return value ? value : '0';
  };

  return (
    <Container>
      {dataDisplay.map(
        ({
          key,
          counterLabel,
          counterTooltipLabel,
          percentageKey,
          mobileCounterLabel,
          hideCounter,
          showQtyInLabel,
        }) => {
          return !hideCounter ? (
            <CounterCard
              fetching={fetching}
              key={key}
              quantity={getQuantity(key, percentageKey)}
              label={getLabel(key, showQtyInLabel, counterLabel, mobileCounterLabel)}
              tooltipLabel={counterTooltipLabel}
            />
          ) : null;
        }
      )}
    </Container>
  );
};

export default CertificationsCounters;
