export const config = {
  routes: {
    uaa: {
      uaaImpersonate: 'impersonate',
      uaaRefreshToken: 'auth/refresh-token/',
      uaaLogout: 'logout/',
    },
    users: {
      getCounts: 'user_invites/counts/',
      getAccounts: 'accounts/',
      getInvites: 'user_invites/',
      resendInvites: 'user_invites/batch_resend_email/',
      getSession: 'users/me/',
      users: 'users/',
      userProfile: 'profile/',
      getAlreadyInvited: 'user_invites/already_invited/',
      sendUserInvites: 'user_invites/batch_email_invite/',
      sendAccountOwnerInvites: 'user_invites/account_owner_invite/',
      getInviteLink: 'invite_links/',
      setTeam: 'user_invites/batch_set_team/',
      setRole: 'user_invites/batch_set_role/',
      setLicense: 'user_invites/batch_set_license/',
      removeInvites: '/user_invites/batch_remove/',
      getStaff: '/users/staff/',
      migrationInfo: '/users/migration_info/',
      notifications: '/users/notifications/',
    },
    licenseAddon: 'license-addon/',
    teams: {
      getTeams: 'teams/',
      createTeams: 'teams/',
      removeTeams: 'teams/batch_remove/',
    },
    actions: {
      setLicense: 'set_license/',
      setRole: 'set_role/',
      setTeam: 'set_team/',
    },
    accounts: {
      getAccounts: 'accounts/',
      getCounts: 'accounts/counts/',
      expire: 'expire/',
      removeAccounts: 'accounts/batch_remove/',
      createAccount: 'accounts/',
      changePlan: 'change_plan/',
      reactivate: 'reactivate/',
      emailDomains: 'email_domains/',
    },
    activityLogs: {
      getActivityLogs: 'activity-logs/',
      getUsers: 'activity-logs/users/',
    },
    notes: 'notes/',
    playlists: {
      getPlaylists: 'playlists',
      getLearningAreas: 'learning-areas',
      getLearningPath: 'learning-paths',
      getCategories: 'categories',
    },
    links: '/links',
    analytics: {
      usersTable: 'users-table',
      teamsTable: 'teams-table',
      coursesTable: 'courses-table',
      series: 'series',
      activeUsers: 'active-users',
      aggregation: 'aggregation',
      preferredContent: 'preferred-content',
      labs: {
        usersTable: 'labs/table-users',
        teamsTable: 'labs/table-teams',
        series: 'labs/series',
      },
      quizzes: {
        usersQuizzTable: '/quizzes/table-users',
        teamsQuizzTable: '/quizzes/table-teams',
      },
      users: {
        usersWithActivity: '/users/table',
        usersWithActivityTotal: 'users/counter-active',
      },
      activities: {
        usersWithActivity: '/activities/table-users',
        teamsWithActivity: '/activities/table-teams',
      },
    },
    reporting: {
      reportsWithId: '/reports',
      reports: '/reports/',
      checkboxes: '/reports-checkboxes/',
    },
    sonar: {
      scores: '/sonar/scores/',
      recommended: '/sonar/recommended-contents',
      recommendedPlaylists: 'sonar/recommended-contents',
    },
    pricingPlans: {
      getPricingPlans: 'pricing_plans/',
    },
    rax: {
      labs: {
        get: '/rax-labs',
        getAvailability: '/rax-labs/product_calendar_availability/',
      },
      bookings: {
        get: '/rax-lab-bookings',
        post: '/rax-lab-bookings/',
        delete: '/rax-lab-bookings/',
      },
      accountRaxLabs: {
        get: '/account-rax-labs',
        post: '/account-rax-labs/',
      },
    },
    genIA: {
      search: 'dev/skill-mapping',
      operations: '/skill-mapping/operations/',
      // Get single operation: /operations/{operationId}
      // Download an operation: /operations/{operationId}/download
    },
    certifications: {
      logs: 'certifications/logs/',
      emptyState: 'certifications/logs/empty-state/',
      stats: 'certifications/stats/',
    },
  },
  loginUrl: {
    dev: {},
    prod: {
      // check before launch
      withRedirect:
        'https://my.ine.com/login?redirect_url=https%3A%2F%2Fbusiness.ine.com&error=Your%20session%20has%20expired,%20please%20login%20to%20continue%20to%20INE%204%20Business',
      noRedirect: 'https://my.ine.com/login',
    },
  },
};
