import styled from 'styled-components/macro';
import { screenSm } from 'constants/styles/breakpoints';

export const Container = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  ${({ isMobile }) =>
    isMobile &&
    `
		padding: 10px;
	`}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const CtaWrapper = styled.div`
  display: none;
  ${screenSm} {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export const Title = styled.h2`
  color: var(--black-dark);
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
`;

export const Counters = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;
