import { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { isMobile, isTablet } from 'react-device-detect';
import Button from 'components/button';
import { screenSm } from 'constants/styles/breakpoints';
import {
  IGenIAOperationsParams,
  IGenIAOperationsResponse,
  IGenIAResponse,
} from 'contexts/gen_ia/types';
import { isDataManager } from 'contexts/session/utilities';
import { SessionContext } from 'contexts/session';
import Notification from 'components/notification';
import ButtonIcon from 'components/buttonIcon';
import SpinnerIMG from 'assets/images/spinner.png';
import { Spin } from 'components/loadings/Spinner';
import Icon from 'components/icons';

import {
  downloadReportAction,
  getOperationsAction,
  searchTextAction,
} from 'contexts/gen_ia/actions';
import Checkbox from 'components/checkbox/Checkbox';
import { Container, Filename, StatusContainer, Table } from './GenIAStyles';
import { reportStatus } from 'contexts/reporting/utils';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0px;
  padding: 20px;
  width: 100%;
  border-radius: 12px;

  ${screenSm} {
    background-color: #fff;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;

const InputSection = styled.div`
  display: flex;
  gap: 10px;
`;

const ResponseSection = styled.div``;

export const Input = styled.input<{ error?: boolean }>`
  height: 44px;
  font-size: 12px;
  padding: 15px 10px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  &::placeholder {
    color: ${({ theme }) => theme.default.colors.fonts.darkGray};
  }
`;

const GenIA = (): JSX.Element => {
  const VIDEOS = 'videos';
  const LABS = 'labs';
  const [prompts, setPrompts] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [fileTypes, setFileTypes] = useState<string[]>([VIDEOS]);
  const [queryResponse, setQueryResponse] = useState<IGenIAResponse | undefined>(undefined);
  const [operations, setOperations] = useState<IGenIAOperationsResponse>({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });
  const { user } = useContext(SessionContext);

  let setCurrentReportUrlLoading: (arg0: boolean) => void;

  if (!isDataManager(user?.uaa_data?.roles?.data?.map(({ id }) => id))) {
    return <Redirect to="/" />;
  }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await searchTextAction({
        prompts: prompts,
        fileTypes: fileTypes.length > 0 ? fileTypes : [VIDEOS], // 'labs' is not implemented yet
      });
      setQueryResponse(response);
      setLoading(false);
    } catch (error) {
      const message = error?.response?.data?.detail || 'An error occurred';
      Notification({ text: message, type: 'error' });
    }
  };

  const handleUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';
    fileInput.click();

    fileInput.addEventListener('change', async (e) => {
      const file = (e.target as HTMLInputElement).files[0];
      const formData = new FormData();
      formData.append('file', file);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result as string;
        setPrompts(text.split('\n'));
      };
      reader.readAsText(file);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (prompts && prompts.length) {
        await handleSubmit();
        getOperations({ page: 1, page_size: 50 });
      }
    };
    fetchData();
  }, [prompts]);

  useEffect(() => {
    getOperations({ page: 1, page_size: 50 });
  }, []);

  const setSkillDescription = (value: string) => {
    setPrompts(value.split(','));
  };

  const handleFilter = (e) => {
    // update fileTypes
    if (e.target.checked) {
      setFileTypes([...fileTypes, e.target.value]);
    }
    if (!e.target.checked) {
      setFileTypes(fileTypes.filter((type) => type !== e.target.value));
    }
  };

  const onDownloadReport = async (id: string, setReportUrlLoading?: (arg0: boolean) => void) => {
    if (setReportUrlLoading) {
      setReportUrlLoading(true);
      setCurrentReportUrlLoading = setReportUrlLoading;
    }
    try {
      const reportUrlResponse = await downloadReportAction(id);
      window.open(reportUrlResponse.download_url);
      setCurrentReportUrlLoading && setReportUrlLoading(false);
    } catch (error) {
      setCurrentReportUrlLoading && setReportUrlLoading(false);
      Notification({
        text: 'There has been a problem generating the link to your report',
        type: 'error',
      });
    }
  };

  const getOperations = async ({ page, page_size }: IGenIAOperationsParams) => {
    setLoading(true);
    try {
      const reportsInitialParams = {
        page: 1,
        page_size: 50,
      };
      if (page) {
        reportsInitialParams.page = page;
      }
      if (page_size) {
        reportsInitialParams.page_size = page_size;
      }

      const operationsResponse = await getOperationsAction(reportsInitialParams);
      setLoading(false);

      setOperations(operationsResponse);
    } catch (error) {
      setLoading(false);
      Notification({ text: 'There has been a problem fetching your reports', type: 'error' });
    }
  };

  const DownloadReportButton = ({
    id,
    completed,
  }: {
    id: string;
    completed: boolean;
  }): JSX.Element => {
    const [reportUrlLoading, setReportUrlLoading] = useState(false);
    return (
      <ButtonIcon
        $secondary
        size="small"
        icon="Download"
        loading={reportUrlLoading}
        disabled={!completed}
        onClick={() => onDownloadReport(id, setReportUrlLoading)}
      />
    );
  };

  const StatusBlock = ({ status }: { status: string }): JSX.Element => {
    switch (status) {
      case reportStatus.completed:
        return (
          <>
            <Icon icon="StatusCompleted" size="small" /> Completed
          </>
        );
      case reportStatus.pending:
      case reportStatus.partialFinished:
        return (
          <>
            <Icon icon="StatusPending" size="small" /> Pending
          </>
        );
      default:
        return (
          <>
            <Icon icon="StatusError" size="small" /> Error
          </>
        );
    }
  };

  return (
    <Section>
      <h1>GEN AI</h1>
      <InputSection>
        <Input
          placeholder={'Search'}
          onChange={(v) => {
            setSkillDescription(v.target.value);
          }}
        />
        <Button $secondary onClick={handleSubmit}>
          Search
        </Button>
        <Button $primary onClick={handleUpload}>
          Upload CSV
        </Button>
      </InputSection>
      <CheckboxGroup>
        <Checkbox
          label={'Videos'}
          value={VIDEOS}
          name="videos_filter"
          onChange={handleFilter}
          defaultChecked={true}
          checked={true}
        />
        <Checkbox
          label={'Labs'}
          value={LABS}
          name="labs_filter"
          onChange={handleFilter}
          defaultChecked={false}
          checked={false}
        />
      </CheckboxGroup>
      <Container loading={loading && isMobile}>
        <Table.Container>
          <Table.Thead hidden={isTablet}>
            <Table.Tr borderBottom={true}>
              <Table.Th withSort={true}>File name</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th withSort={true}>Created</Table.Th>
              <Table.Th>Download</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading && (
              <Table.Loading>
                <Table.Td>
                  <Spin src={SpinnerIMG} style={{ width: '57px', height: '57px' }} />
                </Table.Td>
              </Table.Loading>
            )}
            {!loading && operations?.results.length === 0 && (
              <Table.Tr>
                <Table.Td>{'You do not have reports yet.'}</Table.Td>
              </Table.Tr>
            )}
            {!loading &&
              operations.results?.length > 0 &&
              operations.results?.map((report) => {
                const { id, filename, status, created_at, email } = report;
                return (
                  <Table.Tr borderBottom={true} key={report.id} isTablet={isTablet}>
                    <Table.Td>
                      <Filename>{filename}</Filename>
                      <StatusContainer hiddenSm={true && !isTablet}>
                        <StatusBlock status={status} />
                      </StatusContainer>
                    </Table.Td>
                    <Table.Td hidden={isMobile} hiddenSm={true}>
                      <StatusContainer>
                        <StatusBlock status={status} />
                      </StatusContainer>
                    </Table.Td>
                    <Table.Td hidden={isMobile} hiddenSm={true}>
                      {created_at}
                    </Table.Td>
                    <Table.Td centerContent={false}>
                      <span
                        style={
                          isMobile && status === reportStatus.completed
                            ? { display: 'block' }
                            : { display: 'none' }
                        }
                      >
                        <DownloadReportButton
                          id={id}
                          completed={status === reportStatus.completed}
                        />
                      </span>
                      <span style={!isMobile ? { display: 'block' } : { display: 'none' }}>
                        <DownloadReportButton
                          id={id}
                          completed={status === reportStatus.completed}
                        />
                      </span>
                    </Table.Td>
                  </Table.Tr>
                );
              })}
          </Table.Tbody>
        </Table.Container>
        {/* {!loading && operations.results?.length > 0 && (
          <Pagination
            count={operations.count}
            page={currentPage}
            page_size={pageSize}
            onPagesClick={onPagesClickHandler}
          />
        )} */}
      </Container>
    </Section>
  );
};

export default GenIA;
