import { searchText, downloadOperation, getOperations } from 'services/genIA';
import { IGenIAReportDownload, IGenIAResponse, ISearchGenIAParams } from './types';
import { reportStatus } from '../reporting/utils';

const resultParser = ({ created_at, created_by, filename, id, status, size }) => ({
  id,
  filename,
  created_at: created_at.replace(/T/, ' ').replace(/\..+/, ''),
  created_by: {
    first_name: created_by?.first_name,
    last_name: created_by?.last_name,
  },
  status,
  size: status === reportStatus.completed ? `${(parseInt(size) / 1000).toFixed(2)} KB` : '-',
});

export const searchTextAction = async (params: ISearchGenIAParams): Promise<IGenIAResponse> => {
  const { data } = await searchText(params);
  return data;
};

export const downloadReportAction = async (id: string): Promise<IGenIAReportDownload> => {
  const { data } = await downloadOperation(id);
  return data;
};

export const getOperationsAction = async (params: { page: number; page_size: number }) => {
  const { data } = await getOperations(params.page, params.page_size);
  return data;
};
